import React from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from './components/Layout';
import {
  Content as ContentV2,
  Layout as LayoutV2,
} from './components/LayoutV2';
import ContentNavigation from './components/Navigation/Content';
import HeroHeader from './components/heroheader';
import { HeroHeader as HeroHeaderV2 } from './components/Programme/HeroHeader';
import formatSlug from './lib/slugFormatter';
import MarkdownIt from 'markdown-it';
import TeaserContainer, { TeaserItem } from './components/Teaser';
import { RedirectContent } from './components/Programme/Modules/RedirectContent';
import { RedirectContentFixed } from './components/Programme/Modules/RedirectContentFixed';

export const query = graphql`
  query CategoryQuery($id: Int!) {
    allStrapiProgrammes(
      sort: { fields: Order, order: ASC }
      filter: { Category: { id: { eq: $id } } }
    ) {
      edges {
        node {
          Intro
          Title
          strapiId
          Key_Visual {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 430
                height: 302
                placeholder: DOMINANT_COLOR
              )
            }
          }
          Category {
            id
            Title
          }
          Country {
            Title
          }
        }
      }
    }
    strapiCategories(strapiId: { eq: $id }) {
      Title
      Description
      strapiId
      Key_Visual {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        }
      }
      Metadata {
        id
        Title
        Description
      }
    }
  }
`;

const Category = ({ data, pageContext }) => {
  const category = data.strapiCategories;
  const programmes = data.allStrapiProgrammes.edges.map((elem) => elem.node);
  const metadata = category.Metadata || { Title: false };

  const md = new MarkdownIt({ html: true });
  const contentHtml = category.Description
    ? md.render(category.Description)
    : '';

  const keyVisualUrl = category.Key_Visual ? category.Key_Visual.publicURL : '';

  return (
    <LayoutV2
      className="layout-v2"
      title={metadata.Title || category.Title}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      <HeroHeaderV2
        title={category.Title}
        text={category.Description}
        image={category.Key_Visual}
      />
      <ContentV2>
        <RedirectContent
          props={{}}
          items={programmes.map((programme) => ({
            title: programme.Title,
            content: programme.Intro,
            link: formatSlug([
              programme.Category.Title,
              programme.Country ? programme.Country.Title : 'world',
              programme.Title,
            ]),
            image: programme.Key_Visual ? programme.Key_Visual.publicURL : '',
          }))}
        />
        {
          metadata.Title === 'Working Holidays' ? <RedirectContentFixed /> : <></>
        }
      </ContentV2>
    </LayoutV2>
  );
};

export default Category;
